import { ITPAEventsListenerManager, ITPAStateManager } from './types'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { TpaEventsListenerManagerSymbol } from './symbols'
import { CurrentRouteInfoSymbol } from '@wix/thunderbolt-symbols'
import { ICurrentRouteInfo } from 'feature-router'

export const TpaStateManager = withDependencies(
	[TpaEventsListenerManagerSymbol, CurrentRouteInfoSymbol],
	(tpaEventsListenerManager: ITPAEventsListenerManager, currentRouteInfo: ICurrentRouteInfo): ITPAStateManager => {
		return {
			onUrlChange() {
				const relativeUrl = currentRouteInfo.getCurrentRouteInfo()?.relativeUrl
				if (!relativeUrl) {
					return
				}
				const newState = relativeUrl.substr(relativeUrl.lastIndexOf('/') + 1)
				tpaEventsListenerManager.dispatch('STATE_CHANGED', () => {
					return { newState }
				})
			},
		}
	}
)
